import { SUPPORT_EMAIL } from '../../shared/utils/constants';
import React, { FC } from 'react';
import NextLinkMui5 from './NextLink';
import LinkMui5 from './Link';
import TypographyMui5 from '@mui/material/Typography';

export const GetInTouch: FC = () => (
  <TypographyMui5 variant='body2' gutterBottom>
    Get in touch directly
    <br />
    <LinkMui5
      component={NextLinkMui5}
      href={`mailto:${SUPPORT_EMAIL}`}
      passHref={true}
    >
      {SUPPORT_EMAIL}
    </LinkMui5>
    <br />
    <LinkMui5 component={NextLinkMui5} href='tel:516-518-6512' passHref={true}>
      516-518-6512
    </LinkMui5>
  </TypographyMui5>
);
